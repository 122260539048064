<template>
  <div class="companies">
    <Actions :items="closure.actions" :right="true" />
    <MobileTable
      v-model:selection="store.state.commonExpense.selectedClosures"
      :label="$route.name"
      :columns="closure.columns"
      :data="store.state.commonExpense.closures"
      :rows="50"
    />
    <DesktopTable
      v-model:selection="store.state.commonExpense.selectedClosures"
      :label="$route.name"
      :columns="closure.columns"
      :data="store.state.commonExpense.closures"
      :rows="50"
    />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, actions, dates } from "../../constants";

export default {
  components: { MobileTable, DesktopTable, Actions },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/cierres/nuevo");
    };
    const onModify = () => {
      if (store.state.commonExpense.selectedClosures.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else if (store.state.commonExpense.selectedClosures[0].statusId >= 5) {
        store.commit("setSelectedClosures", []);
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "El cierre seleccionado ya fue enviado!",
          life: 5000,
        });
      } else {
        store.commit("setOpenMode", openMode.MODIFY);
        router.push("/cierres/nuevo");
      }
    };
    const onDetail = () => {
      if (store.state.commonExpense.selectedClosures.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else {
        store.commit("setOpenMode", openMode.DETAIL);
        router.push("/cierres/nuevo");
      }
    };
    const onDownloadExcel = async () => {
      const request = {
        fileName: "Listado de cierres",
        headers: closure.columns.map((item) => item.header),
        data: store.state.commonExpense.closures.map((item) => [
          dates.getPeriodToString(item.period),
          item.prevBalance,
          item.payments,
          item.balance,
          item.surcharge,
          item.commonExpenses,
          item.extraFee,
          item.reserveFound,
          item.commonAreas,
          item.totalDebt,
        ]),
      };
      store.commit("setLoading", true);
      await store.dispatch("COMMON_EXPENSE_DOWNLOAD_EXCEL", request);
      store.commit("setLoading", false);
    };

    const closure = reactive({
      columns: [
        {
          field: "period",
          header: "Período",
          sort: true,
          period: true,
        },
        {
          field: "prevBalance",
          header: "Saldo mes anterior",
          sort: true,
          currency: true,
        },
        {
          field: "payments",
          header: "Pagos",
          sort: true,
          currency: true,
        },
        {
          field: "balance",
          header: "Saldo mes actual",
          sort: true,
          currency: true,
        },
        {
          field: "surcharge",
          header: "Recargo sobre deuda",
          sort: true,
          currency: true,
        },
        {
          field: "commonExpenses",
          header: "Gastos comunes",
          sort: true,
          currency: true,
        },
        {
          field: "extraFee",
          header: "Cuota extra",
          sort: true,
          currency: true,
        },
        {
          field: "reserveFound",
          header: "Fondo reserva",
          sort: true,
          currency: true,
        },
        {
          field: "commonAreas",
          header: "Áreas comunes",
          sort: true,
          currency: true,
        },
        {
          field: "totalDebt",
          header: "Total a pagar",
          sort: true,
          currency: true,
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/cierres/nuevo"),
        },
        {
          name: "Modificar",
          action: onModify,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/cierres/nuevo"),
        },
        {
          name: "Detalle",
          action: onDetail,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/cierres/nuevo"),
        },
        {
          name: "Descargar Excel",
          action: onDownloadExcel,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/cierres/nuevo"),
        },
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedClosures", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(
        actions.commonExpenseActions.findAllClosure,
        store.state.general.selectedBuilding
      );
      store.commit("setLoading", false);
    });

    return { store, closure };
  },
};
</script>

<style scoped></style>
